import { defineStore } from 'pinia'
import _ from 'lodash'
import { usersStore } from './users'
import { apiStore } from '~/store/api'
import { ApplicationFieldType } from '~/models/documents/jDocument'
import { TMachineWorkplace } from '~/composables/mesx/requests/machine'
import { BuiltInStatus } from '~/common/models/status'
import {
  getAllObjectWithKeyNotIncludingInList,
  getAllValueByKey,
  removeNullInArrayString,
} from '~/utils/array'
import { trpc } from '~/composables/mesx/trpc'

export interface IWorkplace {
  id: string
  name: string
  description: null | string
  justification: null | string
  is_deleted: boolean
  updated_by: null | string
  alternative_workplaces: null | any[]
  parentId: null | string
  siteId: string
  alternativeWorkplaces: null | any[]
  updateDate: null | Date
  db_id: number
  site_id: string
  alternativeWorkplacesList: any[]
  siteName: string
  updated_by_id: string | null
  status: BuiltInStatus | null
  update_date: Date
  label: string
  type: string
  machines?: TMachineWorkplace[]
}

export const workplaceStore = defineStore({
  // name of the store
  // it is used in devtools and allows restoring state
  id: 'workplaces',
  // a function that returns a fresh state
  state: () => ({
    operatorCurrentWorkplaces: [] as string[],
    currentWorkplace: {} as IWorkplace,
    workplaces: [] as IWorkplace[],
    workplacesHistory: [] as any[],
    workplacesChildren: [] as any[],
  }),

  // optional getters
  getters: {
    getWorkplaces(): any {
      return this.workplaces
    },

    getWorkplacesHistory(): any {
      return this.workplacesHistory
    },

    getOperatorCurrentWorkplaces(): any[] {
      return this.operatorCurrentWorkplaces
    },

    currentWorkplaceMachineId(): string | null {
      return this.currentWorkplace?.machines?.[0]?.machineId || null
    },
    currentWorkplaceMachineTags(): string[] {
      return (
        this.currentWorkplace?.machines?.[0]?.machine?.machineToTag?.map(
          (e) => e.tagId,
        ) || []
      )
    },
  },
  // optional actions
  actions: {
    async loadWorkplaces(forceFetch = false): Promise<any> {
      const api = apiStore().getApiClient

      let workplaces = await api.getWorkplaces(forceFetch)
      const machines = await trpc.fetchAllMachines.query()

      workplaces = workplaces.map((workplace) => {
        workplace.label = `${workplace.id} - ${workplace.name}`
        workplace.type = ApplicationFieldType.workplace
        workplace.machines = machines
          .filter((machine) =>
            machine.machineToWorkplace.find(
              (machineWorkplace) =>
                machineWorkplace.workplaceId === workplace.db_id,
            ),
          )
          .map((e) => ({
            machine: e,
            machineId: e.id,
            workplaceId: workplace.db_id,
          }))
        return workplace
      })

      workplaces = _.uniqBy(workplaces, (e: any) => e.id)

      let workplacesHistory =
        usersStore().user?.configuration?.visited_workplaces ?? []

      if (workplacesHistory.length) {
        workplacesHistory = workplacesHistory.map((e) => {
          const findWorkplace = workplaces.find((x) => e === x.id)
          e = { ...findWorkplace }
          return e
        })
      }

      const listParentId = removeNullInArrayString(
        getAllValueByKey(workplaces, 'parentId'),
      )
      this.workplacesChildren = getAllObjectWithKeyNotIncludingInList(
        workplaces,
        'id',
        listParentId,
      ).sort((a, b) =>
        a.id.toLocaleLowerCase().localeCompare(b.id.toLocaleLowerCase()),
      )

      this.workplaces = workplaces
      this.workplacesHistory = workplacesHistory
    },
    findAndFormatWorkplace(id: string) {
      const wp = this.findWorkplace(id)

      return `${wp.id} - ${wp.name}`
    },
    findWorkplace(id: string) {
      return this.workplaces.find((wp: any) => wp?.id === id)
    },
    findWorkplaceByDbId(id: number) {
      return this.workplaces.find((wp: any) => wp?.db_id === id)
    },
    getWorkplaceDisplayableName(id: string) {
      const workplace = this.findWorkplace(id)
      if (workplace) {
        return `${workplace.id} - ${workplace.name}`
      }
      return ''
    },
    findWorkplaceByName(name: string) {
      return this.workplaces.find((wp: any) => wp?.name === name)
    },
    filterWorkplaces(ids: any) {
      const workplaces: any = this.workplaces.filter((u: any) => {
        return ids?.length && ids?.includes(u?.id)
      })
      return workplaces
    },
    setCurrentWorkplace(workplace: IWorkplace) {
      this.currentWorkplace = workplace
    },
    setFilteredWorkplace(workplaces) {
      this.operatorCurrentWorkplaces = workplaces
    },
    unshiftWorkplaceHistory(workplace: any) {
      this.workplacesHistory = this.workplacesHistory.filter(
        (wp) => wp.id !== workplace.id,
      )
      this.workplacesHistory.unshift(workplace)
    },
  },
})
