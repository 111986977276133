import { useAcl } from '@clabnet/vue-simple-acl'
import type { JSite } from '~/models/sites'
import { siteStore } from '~/store/site'

export enum welcomeURL {
  OPERATOR_SELECTION = '/operator/workplaces/choose',
  OPERATOR_ALL_WORKPLACES = '/operator/workplaces/all/sessions',
  OPERATOR_FREE = '/operator/workplaces',
  LIBRARY = '/documents',
  SUPERVISION = '/reports',
  ANALYTICS = '/analytics',
}

export const welcomeURLDict = {
  select_workplace_page: welcomeURL.OPERATOR_SELECTION,
  all_workplaces_page: welcomeURL.OPERATOR_ALL_WORKPLACES,
  select_workplaces_with_all: welcomeURL.OPERATOR_FREE,
  library: welcomeURL.LIBRARY,
  supervision: welcomeURL.SUPERVISION,
  analytics: welcomeURL.ANALYTICS,
}

export enum AppSide {
  OperationSpace,
  DesignerSpace,
}

export const getWelcomeURLOptions = () => {
  const acl = useAcl()
  const currentSite: JSite = siteStore().site
  return [
    {
      label: 'welcome_url.operator_selection',
      value: 'select_workplace_page',
      url: welcomeURLDict.select_workplace_page,
      for: AppSide.OperationSpace,
      canSee: (role) => true,
    },
    {
      label: 'welcome_url.operator_free',
      value: 'select_workplaces_with_all',
      url: welcomeURLDict.select_workplaces_with_all,
      for: AppSide.OperationSpace,
      canSee: (role) => true,
    },
    {
      label: 'welcome_url.library',
      value: 'library',
      url: welcomeURLDict.library,
      for: AppSide.DesignerSpace,
      canSee: (role) => role.permissions.documents.read,
    },
    {
      label: 'welcome_url.supervision',
      value: 'supervision',
      url: welcomeURLDict.supervision,
      for: AppSide.DesignerSpace,
      canSee: (role) => role.permissions.reports.read,
    },
    {
      label: 'welcome_url.analytics',
      value: 'analytics',
      url: welcomeURLDict.supervision,
      for: AppSide.DesignerSpace,
      canSee: (role) =>
        currentSite?.flags?.analytics && role.permissions.reports.read,
    },
  ]
}
