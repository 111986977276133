import { defineStore } from 'pinia'
import { getMasterSessionById } from './../controllers/masterSessions/index'
import type {
  MasterSessionModel,
  MasterSessionStatus,
} from '~/models/sessions/MasterSessionModel'
import { getMasterSessions } from '~/controllers/masterSessions'
import { trpc } from '~/composables/mesx/trpc'

export const masterSessionStore = defineStore({
  id: 'masterSessions',
  state: () => ({
    currentMasterSession: {} as MasterSessionModel,
    masterSessions: [] as MasterSessionModel[],
  }),
  getters: {
    getMasterSessions(): MasterSessionModel[] {
      return this.masterSessions
    },
    hasCurrentMasterSession(state) {
      return Object.keys(state.currentMasterSession)?.length > 0
    },
  },
  actions: {
    async loadMasterSessions(): Promise<void> {
      if (this.masterSessions?.length > 0) return

      const masterSessions = await getMasterSessions()
      this.masterSessions = masterSessions
    },
    async loadActiveMasterSessionOnMachine(workplaceId: number) {
      const savedMasterSessionId =
        await trpc.fetchLastActiveProductionSessionOnMachine.query({
          workplaceId,
        })

      this.setCurrentMasterSession(savedMasterSessionId as number)
    },

    addMasterSession(masterSessions) {
      this.masterSessions?.push(masterSessions)
    },
    findMasterSessionSync(id: number | string): MasterSessionModel {
      return this.masterSessions?.find((u: MasterSessionModel) => u?.id === id)
    },
    async findMasterSession(id: number | string): Promise<MasterSessionModel> {
      return (
        this.masterSessions?.find((u: MasterSessionModel) => u?.id === id) ||
        ((await getMasterSessionById(id)) as MasterSessionModel)
      )
    },
    async findMasterSessionByProductionId(
      productionOrderId: string,
    ): Promise<MasterSessionModel> {
      return (
        this.masterSessions?.find(
          (u: any) =>
            u?.master_session_context.production_order_id === productionOrderId,
        ) ||
        ((await getMasterSessionById(
          productionOrderId,
          true,
        )) as MasterSessionModel)
      )
    },
    findMasterSessionByProductionIdState(
      productionOrderId: string,
    ): MasterSessionModel | undefined {
      return this.masterSessions?.find(
        (u: MasterSessionModel) =>
          u?.master_session_context.production_order_id === productionOrderId,
      )
    },
    findMasterSessionByParameters(
      productionOrderId: string,
      workplaceId: string,
    ): MasterSessionModel | undefined {
      return this.masterSessions?.find(
        (u: MasterSessionModel) =>
          u?.master_session_context.production_order_id === productionOrderId &&
          u?.master_session_context.workplace_ids?.includes(workplaceId),
      )
    },
    findMasterSessionByReportId(
      reportId: number,
    ): MasterSessionModel | undefined {
      return this.masterSessions?.find((e) => e.reports_ids.includes(reportId))
    },
    findMasterSessionsOnWorkplace(
      workplaceId: string,
      options = { maxDays: 30 },
    ) {
      const workplaceSessions = this.masterSessions?.filter(
        (masterSession: MasterSessionModel) => {
          return masterSession.master_session_context.workplace_ids?.includes(
            workplaceId,
          )
        },
      )
      return workplaceSessions
    },
    filterMasterSessions(ids: any) {
      const masterSessions: any = this.masterSessions?.filter((u: any) => {
        return ids?.length && ids?.includes(u?.id)
      })
      return masterSessions
    },
    filterMasterSessionsByExpirationDate(maxDays = 10) {
      const expiration = new Date()
      expiration.setDate(expiration.getDate() - maxDays)
      const masterSessions = this.masterSessions.filter(
        (masterSession: MasterSessionModel) => {
          return (
            new Date(masterSession?.update_date)?.getTime() >
            expiration.getTime()
          )
        },
      )
      return masterSessions
    },
    setMasterSessionStatusById(id: string, status: MasterSessionStatus) {
      const index = this.masterSessions?.findIndex(
        (masterSession) => Number(masterSession.id) === Number(id),
      )

      this.masterSessions[index].status = status
      this.masterSessions[index]?.status_history?.unshift(status)
      this.masterSessions[index].update_date = new Date()
    },
    async setCurrentMasterSession(masterSessionId: number | null) {
      this.currentMasterSession = masterSessionId
        ? await getMasterSessionById(masterSessionId.toString())
        : ({} as MasterSessionModel)
    },
    isCurrentMasterSessionFromSessionId(sessionId: string) {
      return (
        this.hasCurrentMasterSession &&
        this.currentMasterSession.session_ids.includes(sessionId)
      )
    },

    async getLatestActiveMasterSessionOnMachine(workplaceId: number) {
      const masterSessionId =
        await trpc.fetchLastActiveProductionSessionOnMachine.query({
          workplaceId,
        })

      if (!masterSessionId) return null
      return this.findMasterSession(masterSessionId)
    },
  },
})
