<script lang="ts" setup>
import { format } from 'date-fns'
import { Icons } from '~/models/Icon'
import { gridStore } from '~/store/grid'
import { siteStore } from '~/store/site'
import type Grid from '~/grid/Grid'

const props = defineProps({
  params: {
    type: Object,
    required: true,
  },
})
const route = useRoute()

const state = reactive({
  rowId: props.params.rowId,
  colId: props.params.column.colDef.colId,
  isActivated: true,
  refName: `cell_inactive_div_${props.params?.colDef?.colId}_${props.params?.rowIndex}`,
  rowIndex: props.params?.node.data.index,
  colIndex:
    props.params?.colDef?.index -
    props.params?.data?.details?.initialShiftIndex,
})

const showHistoryIcon = siteStore().getFlag('history')

const isHistory = computed(() => route.name === 'reports-reportId')

const grid = computed<Grid>(() => {
  if (props.params?.colDef?.reportId) {
    return gridStore(String(props.params?.colDef?.reportId))?.getGrid
  }
  return gridStore().getGrid
})

const cell = computed(() => {
  return grid.value
    ?.getCellByIndex(state.rowIndex, state.colIndex)
    ?.initializeActivationState(props.params)
})

const latestInputData = computed(() => {
  return cell?.value?.getLatestInputData()
})

const isActivated = computed(() => {
  return cell?.value?.isActivated ?? true
})

const currentValue = computed(() => {
  return props.params?.answer?.value ?? cell?.value?.latestValue
})

const isEditable = computed(() => {
  return cell?.value?.isEditable(props.params)
})

const init = () => {
  if (state.isActivated && props.params.value === '')
    props.params.node.setDataValue(props.params.column.colId, false)
}

const activateCell = () => {
  cell.value?.setIsActivated(isEditable.value)
}

const checkedHandler = (event) => {
  const value = event.target.checked ? true : null
  const colId = props.params.column.colId
  props.params.node.setDataValue(colId, value)
}

const history = computed(() => {
  return cell?.value?.getHistoryTooltip()
})

init()
</script>

<template>
  <div
    v-if="!cell?.step?.hidden"
    :class="[
      `_data-cy-column-${state.colIndex}`,
      `_data-cy-row-${state.rowIndex}`,
    ]"
    data-cy="input-container"
    :data-cy-column="state.colIndex"
    class="_data-cy-input-type-checkbox"
  >
    <div
      v-if="cell?.hasHistory && showHistoryIcon"
      v-can.any="['read-report', 'create-report']"
      :tooltip="grid.onExport ? history : null"
      class="absolute right-1 top-1"
      data-cy="history-button"
      @click.stop="gridStore().setHistoryModalCell(cell ?? null)"
    >
      <component
        :is="Icons.RECENTLY_VIEWED"
        class="h-5 w-5 cursor-pointer text-grey-500"
      />
    </div>
    <div
      v-if="latestInputData?.updated_by"
      v-can.any="['read-report', 'create-report']"
      class="absolute bottom-1 right-1 flex flex-col-reverse items-center pb-2"
      data-cy="avatar-button"
    >
      <AvatarMultiple
        :user-ids="[latestInputData?.updated_by]"
        :update-date="latestInputData?.update_date"
        :hide-full-name="!grid?.onExport"
        display-amount
        is-enable-modal
      />
      <span
        v-if="latestInputData?.update_date && grid?.onExport"
        class="text-center text-sm text-gray-600"
        >{{
          format(new Date(latestInputData?.update_date), 'dd/MM/yy - HH:mm')
        }}</span
      >
    </div>
    <input
      v-if="isActivated && (!grid?.onExport || !currentValue)"
      :checked="currentValue"
      :disabled="isHistory || !isEditable"
      class="h-5 w-5"
      type="checkbox"
      @click="checkedHandler($event)"
    />
    <component
      :is="Icons.CHECKBOX_CHECKED"
      v-else-if="isActivated && grid?.onExport"
      :checked="currentValue"
      class="h-5 w-5"
    />
    <div
      v-else
      :id="state.refName"
      class="rounded bg-[#CBCCCD] px-2 py-1.5 text-[#FFFFFF]"
      @click="activateCell"
    >
      N/A
    </div>
  </div>
</template>
