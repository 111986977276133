import { WorkflowType } from '../../components/Settings/Workflows/config'
import type { MasterSessionModel } from './../../models/sessions/MasterSessionModel'
import apiHelper from '~/helpers/ApiHelper'
import { workflowStore } from '~/store/workflows'

export const updateMasterSessionById = async (
  id: number,
  payload: any,
): Promise<MasterSessionModel> => {
  return apiHelper.updateMasterSessionById(id, {
    ...payload,
  })
}

export const createMasterSession = async (
  masterSession: MasterSessionModel,
): Promise<MasterSessionModel> => {
  const workflow = workflowStore().findWorkflowByType(
    WorkflowType.PRODUCTION_ORDERS,
  )
  const createdMasterSession = await apiHelper.createMasterSession({
    ...masterSession,
    workflow_id: workflow?.id,
  })
  return createdMasterSession
}

export const updateMasterSessionTeam = async (
  masterSessionId: number,
  chainManager: string[],
  teamMembers: string[][],
  isCertified: boolean,
): Promise<any> => {
  await apiHelper.updateMasterSessionById(masterSessionId, {
    updateType: 'team',
    chainManager,
    teamMembers,
    isCertified,
  })
}

export const getMasterSessions = async (): Promise<MasterSessionModel[]> => {
  return await apiHelper.getMasterSessions()
}

export const getMasterSessionById = async (
  id: string,
  byProductionOrderId = false,
): Promise<MasterSessionModel> => {
  return await apiHelper.getMasterSessionById(id, byProductionOrderId)
}
