import { Frequency } from '~/models/documents/jDocument'
import { parseExpression } from 'cron-parser'
import { JReport } from '~/models/documents/jReport'

export const generateCronDate = (frequency: Frequency) => {
  if (frequency.type === 'minute') {
    return `*/${frequency.every} * * * *`
  }
  if (frequency.type === 'hour') {
    return `0 */${frequency.every}  * * *`
  }
  return `0 0 */${frequency.every} * *`
}

export const getTodayCronDates = (cronExpression: string) => {
  const today = new Date()

  const startOfDay = new Date(today.setHours(0, 0, 0, 0))
  const endOfDay = new Date(today.setHours(23, 59, 59, 999))

  const interval = parseExpression(cronExpression, {
    currentDate: startOfDay,
  })

  interval.prev()

  const dates: string[] = []

  let nextDate = interval.next()
  while (nextDate.toDate() <= endOfDay) {
    if (nextDate.toDate() >= startOfDay) {
      dates.push(getTimeFrequencyDate(nextDate))
    }
    nextDate = interval.next()
  }

  return dates
}

export const getTimeFrequencyDate = (date: Date) => {
  const hours = String(date.getHours()).padStart(2, '0')
  const minutes = String(date.getMinutes()).padStart(2, '0')
  return `${hours}:${minutes}`
}
export const getStartInterval = (
  frequency: Frequency,
  startDate: Date | string | number,
) => {
  const cronExpression = generateCronDate(frequency)
  return parseExpression(cronExpression, { currentDate: startDate })
}

export const getNthColumnDate = (
  frequency: Frequency,
  startDate: Date | string | number,
  index: number,
) => {
  const startInterval = getStartInterval(frequency, startDate)
  let date
  for (let i = 0; i < index; i++) {
    date = startInterval.next()
  }

  return getTimeFrequencyDate(date.toDate())
}

export const getReportStartingTime = (
  frequency: Frequency,
  report: JReport,
) => {
  let startDate = report.creation_date
  if (!frequency.startWithReportLaunch) {
    const startingAt = frequency.startingAt.split(':')

    const currentDate = new Date()
    currentDate.setHours(startingAt[0])
    currentDate.setMinutes(startingAt[1])
    startDate = currentDate
  }

  return startDate
}
