import { defineStore } from 'pinia'
import { usersStore } from './users'
import { JAction } from '~/models/projects/JAction'

export const actionsStore = defineStore({
  id: 'actions',
  state: () => ({
    actions: [] as JAction[],
  }),
  actions: {
    async loadActions() {
      const actions = [
        {
          id: 1,
          name: 'Former le personnel',
          description:
            "Collecte de données: Recueillir des données sur les temps d'arrêt et les erreurs lors des précédents changements de gamme.",
          assigneesIds: [usersStore().user.id],
          projectId: 1,
          priority: 3,
          deadline: new Date(),
          status: 1,
          category: 'Production',
          attachments: ['Maintenance 1er niveau'],
        },
        {
          id: 2,
          name: 'Mettre en place des contrôles qualité supplémentaires',
          description: '',
          assigneesIds: [usersStore().user.id],
          projectId: 2,
          priority: 3,
          deadline: new Date(),
          status: 1,
          category: 'Production',
        },
        {
          id: 3,
          name: 'Automatiser les tâches manuelles et répétitives dans le processus de traitement des commandes',
          description: '',
          assigneesIds: [usersStore().user.id],
          projectId: 3,
          priority: 3,
          deadline: new Date(),
          status: 3,
          category: 'Production',
          attachments: ['Maintenance 1er niveau'],
        },
        {
          id: 4,
          name: "Impliquer les opérateurs dans l'identification et la résolution des problèmes liés aux rebuts",
          description: '',
          assigneesIds: [usersStore().user.id],
          projectId: 2,
          priority: 3,
          deadline: new Date(),
          status: 1,
          category: 'Production',
        },
        {
          id: 5,
          name: "Solliciter les commentaires des clients sur leur expérience de livraison et identifier les domaines d'amélioration",
          description: '',
          assigneesIds: [usersStore().user.id],
          projectId: 3,
          priority: 3,
          deadline: new Date(),
          status: 1,
          category: 'Production',
        },
        {
          id: 6,
          name: "Analyser les temps d'arrêt",
          description: '',
          assigneesIds: [usersStore().user.id],
          projectId: 1,
          priority: 3,
          deadline: new Date(),
          status: 2,
          category: 'Production',
        },
        {
          id: 7,
          name: 'Mettre en place une maintenance préventive',
          description: '',
          assigneesIds: [usersStore().user.id],
          projectId: 1,
          priority: 3,
          deadline: new Date(),
          status: 2,
          category: 'Production',
        },
        {
          id: 8,
          name: 'Améliorer la gestion des pièces de rechange',
          description: '',
          assigneesIds: [usersStore().user.id],
          projectId: 1,
          priority: 3,
          deadline: new Date(),
          status: 2,
          category: 'Production',
        },
        {
          id: 9,
          name: "Optimisation de l'approvisionnement",
          description: '',
          assigneesIds: [usersStore().user.id],
          projectId: 1,
          priority: 3,
          deadline: new Date(),
          status: 1,
          category: 'Production',
        },
      ]

      /* actions.map((e) => {
        e.category = settingsStore().findSettingById(e.category)?.value
      }) */

      this.actions = actions
    },
  },
})
