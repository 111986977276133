<script setup lang="ts">
import { format } from 'date-fns'
import { Icons } from '~/models/Icon'
import { gridStore } from '~/store/grid'
import { siteStore } from '~/store/site'
import type Grid from '~/grid/Grid'

const props = defineProps({
  params: {
    type: Object,
    required: true,
  },
})

const state = reactive({
  rowIndex:
    props.params?.node?.data?.index !== undefined
      ? props.params?.node.data?.index
      : props?.params?.rowIndex,
  colIndex:
    props.params?.colDef?.index -
    props.params?.data?.details?.initialShiftIndex,
})

const grid = computed<Grid>(() => {
  if (props.params?.colDef?.reportId) {
    return gridStore(String(props.params?.colDef?.reportId))?.getGrid
  }
  return gridStore().getGrid
})

const cell = computed(() => {
  return grid.value
    ?.getCellByIndex(state.rowIndex, state.colIndex)
    ?.initializeActivationState(props.params)
})

const latestInputData = computed(() => {
  return cell?.value?.getLatestInputData()
})

const isActivated = computed(() => {
  return cell.value?.isActivated
})

const isTargetIconVisible = computed(() => {
  const lastTargets = cell?.value?.step?.last_targets?.find(
    (e) => e.init_col_id === cell?.value?.colIndex,
  )

  if (lastTargets && cell?.value?.step?.is_dynamic) return true
  return false
})

const showHistoryIcon = siteStore().getFlag('history')

const history = computed(() => {
  return cell?.value?.getHistoryTooltip()
})
</script>

<template>
  <div
    v-if="!cell?.step?.hidden"
    :data-cy-column="state.colIndex"
    class="_data-cy-ag-grid-string"
    :class="[
      `_data-cy-column-${state.colIndex}`,
      `_data-cy-row-${props.params.rowIndex}`,
      `_data-cy-input-type-${props.params.data.type.toLowerCase()}`,
    ]"
  >
    <div
      v-if="isActivated"
      data-cy="string"
    >
      <div
        v-if="isTargetIconVisible"
        class="absolute top-1"
        :class="`${
          cell?.hasHistory && showHistoryIcon ? 'right-8' : 'right-1'
        }`"
      >
        <component
          :is="Icons.CENTER_CIRCLE"
          class="text-grey-500"
        />
      </div>

      <div
        v-if="(cell?.hasHistory && showHistoryIcon) || latestInputData?.reason"
        v-can.any="['read-report', 'create-report']"
        :tooltip="grid.onExport ? history : null"
        class="absolute right-1 top-1"
        data-cy="history-button"
        @click.stop="gridStore().setHistoryModalCell(cell ?? null)"
      >
        <component
          :is="Icons.RECENTLY_VIEWED"
          class="h-5 w-5 cursor-pointer text-grey-500"
        />
      </div>
      <div
        v-if="latestInputData?.value"
        v-can.any="['read-report', 'create-report']"
        class="absolute bottom-1 right-1 flex flex-col-reverse items-center pb-2"
        data-cy="avatar-button"
      >
        <AvatarMultiple
          :user-ids="[latestInputData?.updated_by]"
          :update-date="latestInputData?.update_date"
          :hide-full-name="!grid?.onExport"
          display-amount
          is-enable-modal
        />
        <span
          v-if="latestInputData?.update_date && grid?.onExport"
          class="text-center text-sm text-gray-600"
          >{{
            format(new Date(latestInputData?.update_date), 'dd/MM/yy - HH:mm')
          }}</span
        >
      </div>
      <p
        class="text-sm"
        v-html="cell.latestValueAsHtml ?? cell.latestValue"
      />
    </div>
    <div
      v-else
      class="rounded bg-[#CBCCCD] px-2 py-1.5 text-[#FFFFFF]"
    >
      N/A
    </div>
  </div>
</template>
