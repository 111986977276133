import { createAcl, defineAclRules } from '@clabnet/vue-simple-acl'
import loggerHelper from '~/helpers/LoggerHelper'
import type { JReport } from '~/models/documents/jReport'
import type { DocumentCategory } from '~/models/settings/settings'
import { rolesStore } from '~/store/roles'
import { settingsStore } from '~/store/settings'
import { usersStore } from '~/store/users'
import reportWorkflowService from '~/services/workflows/workflowService'
import type { TypedWorkflowDto } from '~/services/workflows/workflowService'
import { WorkflowType } from '~/components/Settings/Workflows/config'

const rules = () =>
  defineAclRules((setRule) => {
    const actions = rolesStore().actions
    const ressources = rolesStore().ressources

    ressources
      .map((e) => e.slice(0, -1)) // slice here to remove the "s" from ressources
      .forEach((ressource) => {
        actions.forEach((action) => {
          setRule(`${action}-${ressource}`, () => {
            return usersStore().currentUserHasPermission(ressource, action)
          })
        })
      })

    setRule(
      'edit-report',
      (
        _,
        document,
        report?: JReport | undefined,
        masterSession?: any,
        session?: any,
      ) => {
        if (!document || !report || !session) return false

        const category = settingsStore().settings.find(
          (e) => e.id === document?.category,
        ) as DocumentCategory

        if (!category)
          loggerHelper.logError(
            `Document ${document?.id} has a category (${document?.category}) that doesn't exist or not on the store`,
          )

        const rolesAccess =
          category?.roles_access.map((e: any) => e.value) || []

        if (!rolesAccess.length)
          loggerHelper.logError(
            `Document ${document?.id} doesn't have roles_access on category ${category?.id}`,
          )

        let canEditDocumentBasedOnWorkflow = true

        const workflowDtos = [] as TypedWorkflowDto[]

        if (document?.workflow_id)
          workflowDtos.push({
            type: WorkflowType.REPORTS,
            status: report.status,
            workflowId: document.workflow_id,
          })
        if (session)
          workflowDtos.push({
            type: WorkflowType.OPERATIONS,
            status: session.status,
            workflowId: session.workflow_id,
          })
        if (masterSession)
          workflowDtos.push({
            type: WorkflowType.PRODUCTION_ORDERS,
            status: masterSession.status,
            workflowId: masterSession.workflow_id,
          })

        canEditDocumentBasedOnWorkflow =
          reportWorkflowService.checkIfHasRightsOnCurrentStatus(workflowDtos)

        return (
          usersStore().currentUserHasPermission('report', 'modify') &&
          rolesAccess.some((e) => usersStore().user.role_ids.includes(e)) &&
          canEditDocumentBasedOnWorkflow
        )
      },
    )
  })

const rbac = createAcl({
  rules,
})

export default rbac
