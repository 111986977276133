<script setup lang="ts">
import { ToastIcon } from '~/models/Toast'
import { Icons } from '~/models/Icon'

const props = defineProps({
  show: {
    type: Boolean,
    required: true,
    default: false,
  },
  status: {
    type: String,
    required: false,
    default: 'success',
  },
  displayInterval: {
    type: Number,
    required: false,
    default: 5000,
  },
  title: {
    type: String,
    required: true,
    default: '',
  },
  content: {
    type: String,
    required: true,
    default: '',
  },
  dontHide: {
    type: Boolean,
    required: false,
    default: false,
  },
  hideCloseBtn: {
    type: Boolean,
    required: false,
    default: false,
  },
  bigger: {
    type: Boolean,
    required: false,
    default: false,
  },
})
const emit = defineEmits(['toastDisplay'])
const state = reactive({
  show: false,
})
watch(
  () => props.show,
  (newVal, oldValue) => {
    state.show = newVal
    if (state.show && !props.dontHide) {
      setTimeout(() => {
        state.show = false
        emit('toastDisplay', false)
      }, props.displayInterval)
    }
  },
)
const loadState = () => {
  state.show = props.show
}
loadState()
</script>

<template>
  <div
    aria-live="assertive"
    data-cy="toast"
    class="pointer-events-none fixed inset-0 z-[999] flex items-end px-4 py-6 sm:items-start sm:p-6"
  >
    <div class="flex w-full flex-col items-center space-y-4 sm:items-end">
      <!-- Notification panel, dynamically insert this into the live region when it needs to be displayed -->
      <transition
        enter-active-class="transform ease-out duration-300 transition"
        enter-from-class="translate-y-2 opacity-0 sm:translate-y-0 sm:translate-x-2"
        enter-to-class="translate-y-0 opacity-100 sm:translate-x-0"
        leave-active-class="transition ease-in duration-100"
        leave-from-class="opacity-100"
        leave-to-class="opacity-0"
      >
        <div
          v-if="state.show"
          class="pointer-events-auto w-full max-w-sm overflow-hidden rounded-lg bg-white shadow-lg ring-1 ring-black ring-opacity-5"
        >
          <div :class="{ 'p-4': !props.bigger, 'p-6': props.bigger }">
            <div
              class="flex items-start"
              :class="{ 'gap-4': props.bigger }"
            >
              <div class="flex-shrink-0">
                <component
                  :is="Icons.CHECKMARK_OUTLINE"
                  v-if="props.status === ToastIcon.SUCCESS"
                  data-cy="success-icon"
                  class="mx-1 mr-4 h-8 w-8 cursor-pointer text-green-400"
                />
                <component
                  :is="Icons.ERROR"
                  v-if="props.status === ToastIcon.ERROR"
                  class="mx-1 mr-4 h-8 w-8 cursor-pointer text-red-400"
                />
                <component
                  :is="Icons.INFORMATION"
                  v-if="props.status === ToastIcon.INFORMATION"
                  class="mx-1 mr-4 h-8 w-8 cursor-pointer text-orange-400"
                />
                <component
                  :is="Icons.WARNING"
                  v-if="props.status === ToastIcon.WARNING"
                  class="mx-1 mr-4 h-8 w-8 cursor-pointer text-orange-400"
                />
                <div
                  v-if="props.status === ToastIcon.LOADING"
                  class="flex items-center justify-center rounded-lg bg-duskBlue p-2"
                >
                  <div role="status">
                    <svg
                      aria-hidden="true"
                      class="h-6 w-6 animate-spin fill-white text-duskBlue"
                      viewBox="0 0 100 101"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <path
                        d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z"
                        fill="currentColor"
                      />
                      <path
                        d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z"
                        fill="currentFill"
                      />
                    </svg>
                    <span class="sr-only">{{ $t('global.loading') }}</span>
                  </div>
                </div>
              </div>
              <div
                class="ml-1 flex w-0 flex-1 flex-col pt-0.5"
                :class="{ 'gap-4': props.bigger }"
              >
                <p
                  class="text-left text-sm font-medium text-gray-900"
                  :class="{ 'text-lg text-duskBlue': props.bigger }"
                >
                  {{ $t(props.title) }}
                </p>
                <p
                  class="mt-1 text-left text-sm text-gray-500"
                  :class="{ 'text-md': props.bigger }"
                >
                  {{ $t(props.content) }}
                </p>
              </div>
              <div
                v-if="!props.hideCloseBtn"
                class="ml-4 flex flex-shrink-0"
              >
                <button
                  class="inline-flex rounded-md bg-white text-gray-400 hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2"
                  @click="state.show = false"
                >
                  <span class="sr-only">Close</span>
                  <component
                    :is="Icons.CLOSE"
                    class="h-5 w-5"
                  />
                </button>
              </div>
            </div>
          </div>
        </div>
      </transition>
    </div>
  </div>
</template>
