import Cell from './Cell'
import type { JInputData } from '~/models/report/jInputData'
import { StepType, JStep } from '~/models/documents/jStep'
import { OperatorType } from '~/services/steps/CalculationStepService'
import { replaceComputePlaceholders } from '~/services/grid'

export type CalculToken = {
  isInput: boolean
  value?: string
  text?: string
  type?: string | null
  stepName?: string
}

export class CalculatorCell extends Cell {
  private stepMap: Map<string, string>
  private steps: JStep[]

  constructor(
    rowIndex: number,
    colIndex: number,
    inputs: JInputData[],
    step: JStep,
    isHistory: boolean,
    onExport: false,
    reportId: null,
    steps: JStep[],
  ) {
    if (step.type !== StepType.Calculator) throw new Error('Type mismatch')

    super(
      rowIndex,
      colIndex,
      inputs,
      step,
      isHistory,
      onExport,
      reportId,
      steps,
    )

    this.steps = steps
    this.stepMap = new Map(steps?.map((step) => [step.id, step.name])) || []
  }

  get latestValue(): string {
    const value = super.latestValue

    return value?.result ?? value
  }

  get calculContext(): string[] | null {
    const lastInput = this.getLatestInputData()

    return lastInput?.context?.calculContext || null
  }

  replaceComputePlaceholders() {
    const lastAnswer = this.getLatestInputData()
    if (!lastAnswer) return ''

    return replaceComputePlaceholders(
      this._step.calcul,
      lastAnswer,
      this.steps,
      2,
    )
  }

  getHistoryHTML(): string {
    return this.getHistory()
      .filter((value, index) => index > 0)
      .map((history) => {
        return `${this.getHistoryLineText(history, ',')} <br>`
      })
      .join(',')
  }

  get calculTokens(): CalculToken[] {
    const tokens: CalculToken[] = []
    const calcul = this.step?.calcul || ''
    const regex =
      /(%s\d+)|(%d)|(\bMoy\b|\bMin\b|\bMax\b)|([+\-*/();])|(\d+[\.,]?\d*)|(\w+)/g

    const numberSteps = this.steps?.filter(
      (step) => step.type === StepType.Number || step.type === StepType.Measure,
    )

    const usedNumberSteps = numberSteps.filter((step) =>
      calcul.includes(step.id),
    )

    // Create a map of step IDs to placeholders
    const stepIdToPlaceholder = new Map<string, string>()
    usedNumberSteps.forEach((step, index) => {
      stepIdToPlaceholder.set(step.id, `%s${index}`)
    })

    // Replace step IDs with placeholders in the calculation string
    let processedCalcul = calcul
    stepIdToPlaceholder.forEach((placeholder, stepId) => {
      const stepIdRegex = new RegExp(stepId, 'g')
      processedCalcul = processedCalcul.replace(stepIdRegex, placeholder)
    })

    const parts = processedCalcul.match(regex) || []
    const statisticalOperators = ['Moy', 'Min', 'Max']
    const arithmeticOperators = ['+', '-', '*', '/', '(', ')', ';']
    let index = 0

    const stepValueMap = new Map(
      usedNumberSteps.map((step) => [
        step.id,
        step.answers?.find((a) => a.col_id === this._colIndex)?.value,
      ]),
    )

    parts.forEach((part) => {
      if (part.trim() === '') return

      if (part.startsWith('%s')) {
        // Replace the placeholder back to the original step ID
        const stepIndex = parseInt(part.slice(2))
        const stepId = Array.from(stepIdToPlaceholder.keys())[stepIndex]
        const stepValue = stepValueMap.get(stepId)

        tokens.push({
          isInput: false,
          value: stepValue || this.stepMap.get(stepId),
          stepName: this.stepMap.get(stepId),
          type: 'Step',
        })
        index++
      } else if (part === '%d') {
        tokens.push({
          isInput: true,
          value: this.calculContext ? this.calculContext[index++] : '',
          type: 'Input',
        })
      } else if (statisticalOperators.includes(part)) {
        tokens.push({
          isInput: false,
          value: part,
          type: OperatorType.Statistical,
        })
      } else if (arithmeticOperators.includes(part)) {
        tokens.push({
          isInput: false,
          value: part,
          type: OperatorType.Arithmetic,
        })
      } else {
        tokens.push({
          isInput: false,
          value: part,
          type: part.match(/\d+/) ? 'Number' : null,
        })
      }
    })

    return tokens
  }
}

export default CalculatorCell
