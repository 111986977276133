import { defineStore } from 'pinia'
import { actionsStore } from './actions'
import { usersStore } from './users'
import {
  JProject,
  PriorityStatus,
  ProjectStatus,
} from '~/models/projects/JProject'

export const projectsStore = defineStore({
  id: 'projects',
  state: () => ({
    projects: [] as JProject[],
  }),
  actions: {
    async loadProjects() {
      const projects: JProject[] = [
        {
          id: 1,
          name: 'Amélioration de la disponibilité machine',
          description:
            "Améliorer l'efficacité et la qualité du processus de changement de gamme pour réduire les temps d'arrêt, les défauts de qualité et les pertes de productivité",
          assigneesIds: [usersStore().user.id],
          deadline: new Date(),
          priority: PriorityStatus.HIGH,
          budget: 2398,
          status: ProjectStatus.IN_PROGRESS,
          actions: [],
          workplaces: 'UAP3',
        },
        {
          id: 2,
          name: 'Diminuer le taux des rebuts',
          description: '',
          assigneesIds: [usersStore().user.id],
          deadline: new Date(),
          priority: PriorityStatus.LOW,
          budget: 2398,
          status: ProjectStatus.IN_PROGRESS,
          actions: [],
          workplaces: 'UAP4',
        },
        {
          id: 3,
          name: 'Réduire le délai des livraisons',
          description: '',
          assigneesIds: [usersStore().user.id],
          deadline: new Date(),
          priority: PriorityStatus.MEDIUM,
          budget: 2398,
          status: ProjectStatus.NEW,
          actions: [],
          workplaces: 'UAP2',
        },
      ]

      projects.map((project) => {
        project.actions = actionsStore().actions.filter(
          (action) => action.projectId === project.id,
        )
      })

      this.projects = projects
    },
    findProjectById(id: number) {
      return this.projects.find((e) => e.id === id)
    },
  },
})
