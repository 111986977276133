import { JStep } from '~/models/documents/jStep'

interface ICalculationStepService {
  getMathOperator(): IOperator[]
  prepareCalcul(
    numberSteps: JStep[],
    calcul: string,
    numberTrad: string,
  ): string
}

export enum OperatorType {
  Arithmetic = 'Arithmetic',
  Statistical = 'Statistical',
}

interface IOperator {
  value: string
  label: string
  type: OperatorType
}

class CalculationStepService implements ICalculationStepService {
  constructor() {}
  getMathOperator(): IOperator[] {
    return [
      { label: '+', value: '+', type: OperatorType.Arithmetic },
      { label: '-', value: '-', type: OperatorType.Arithmetic },
      { label: '(', value: '(', type: OperatorType.Arithmetic },
      { label: ')', value: ')', type: OperatorType.Arithmetic },
      { label: '÷', value: '/', type: OperatorType.Arithmetic },
      { label: 'x', value: '*', type: OperatorType.Arithmetic },
      { label: ';', value: ';', type: OperatorType.Arithmetic },
      { label: 'Min', value: 'Min (  ;  )', type: OperatorType.Statistical },
      { label: 'Max', value: 'Max (  ;  )', type: OperatorType.Statistical },
      { label: 'Moy', value: 'Moy (  ;  )', type: OperatorType.Statistical },
    ]
  }

  prepareCalcul(
    numberSteps: JStep[],
    calcul: string,
    numberTrad: string,
  ): string {
    const stepNames = numberSteps.map((step) => step.name)
    const stepMap = new Map(
      stepNames.map((name, index) => [name, numberSteps[index].id]),
    )

    let calculExpression = calcul
    stepMap.forEach((id, name) => {
      const regex = new RegExp(name, 'g')
      calculExpression = calculExpression.replace(regex, id)
    })

    return calculExpression.replace(new RegExp(numberTrad, 'g'), '%d')
  }
}

const calculationStepService = new CalculationStepService()

export default calculationStepService
